import moment from 'moment';
import { agendaMensal, ativsByArea } from '../components/AgendaHelpers';
import { onceGetAgenda, getActivity, getActivitySimple } from '../firebase/agendafirestore'
import { ativsByDay } from '../helpers/activitiesHelpers';

import { agendafire } from '../firebase';

const getAgendaAnyway = async (agendaId) => {

  const storeNameAgenda = `agendaState/${agendaId}`;
  const agendaSessionStorageString = localStorage.getItem(storeNameAgenda);
	

  if (agendaSessionStorageString) {
    const agendaSessionStorage = JSON.parse(agendaSessionStorageString);
    const { agenda } = agendaSessionStorage;
		// console.log("​getAgendaAnyway -> agenda", agenda)
    return agenda;

  } else {
    console.log('. . . . .  FETCHING AGENDA BASIC DATA . . . ')
    // return onceGetAgenda(agendaId).then(agenda => {return agenda.data()})
    let res =  await onceGetAgenda(agendaId);
		// console.log("​getAgendaAnyway -> res", res)
    return res;
  }

}

const getAtividadeAnyway = async (agendaId, atividadeId) => {

  const storeNameAgenda = `agendaState/${agendaId}`;
  const agendaSessionStorageString = localStorage.getItem(storeNameAgenda);

  if (agendaSessionStorageString) {
    const agendaSessionStorage = JSON.parse(agendaSessionStorageString);
    const { atividades } = agendaSessionStorage;

    const result = atividades.filter(ativ => (ativ.atividadeId === atividadeId) || (ativ.id === atividadeId));

    return result[0];

  } else {
    let res =  await getActivity(agendaId, atividadeId);
    return res;
  }
}


const getAgendaData = async (agenda) => { 

  console.log('GETTING AGENDA DATA')
  let storeNameAgenda = `agendaState/${agenda.id}`;
  
  let days = await agendafire.getDays(agenda.id).get()
  days = days.docs.map(function (documentSnapshot) {
    return documentSnapshot.data() 
  })

  let ativs = await agendafire.getActivities(agenda.id).get()
  ativs = ativs.docs.map(function (documentSnapshot) {
    return documentSnapshot.data() 
  })

  if (ativs && ativs.length > 0) {
    let aByDay = ativsByDay(ativs);
    let _agendaMensal = agendaMensal(days,aByDay);
    let byArea = ativsByArea(ativs);

    let agendaData = {
      agenda: agenda,
      byDay: _agendaMensal,
      byArea: byArea,
      atividades: ativs, 
      days
    }
  
    localStorage.setItem(storeNameAgenda, JSON.stringify(agendaData));
  }
      
}


const getAgendaDataById = async (agendaId) => {
  
  console.log('GETTING AGENDA DATA by id', agendaId)

  let days = await agendafire.getDays(agendaId).get()
  
  days = days.docs.map(function (documentSnapshot) {
    return documentSnapshot.data() 
  })  

  let ativs = await agendafire.getActivities(agendaId).get()
  
  ativs = ativs.docs.map(function (documentSnapshot) {
    return documentSnapshot.data() 
  })

  if (ativs && ativs.length > 0) {
    let aByDay = ativsByDay(ativs);
    let _agendaMensal = agendaMensal(days,aByDay);
    let byArea = ativsByArea(ativs);

    const agenda = await onceGetAgenda(agendaId)

    const agendaData = {
      agenda,
      byDay: _agendaMensal,
      byArea: byArea,
      atividades: ativs, 
      days
    }
    return agendaData
  }

      
}



// const getAtividadeAnyway2 = async (agendaId, atividadeId) => {

//   const storeNameAgenda = `agendaState/${agendaId}`;
//   const agendaSessionStorageString = localStorage.getItem(storeNameAgenda);

//   if (agendaSessionStorageString) {
//     const agendaSessionStorage = JSON.parse(agendaSessionStorageString);
//     const { atividades } = agendaSessionStorage;
//     const result = atividades.filter(ativ => ativ.id === atividadeId);
//     return result[0];

//   } 

//   return getActivitySimple(agendaId, atividadeId).get()
//   .then(result => {
//     return result.data()
//   })
//   .catch(err => {
//     return { error: "Erro ao buscar atividade."}
//   })  
// }




const getAtividadeAnyway2 = async (agendaId, atividadeId) => {


  const storeNameAgenda = `agendaState/${agendaId}`;
  const agendaSessionStorageString = localStorage.getItem(storeNameAgenda);

  if (agendaSessionStorageString) {
    const agendaSessionStorage = JSON.parse(agendaSessionStorageString);
    // console.log("🚀 ~ getAtividadeAnyway2 ~ agendaSessionStorage:", agendaSessionStorage)
    const { atividades } = agendaSessionStorage;
		
    // const result = atividades.filter(ativ => ativ.atividadeId === atividadeId);
    const result = atividades.filter(ativ => ativ.id === atividadeId ||  ativ.atividadeId == atividadeId );
		// console.log("​getAtividadeAnyway -> result", result)

    return result[0];

  } 


  if (!navigator.onLine) {
    throw new Error("Dispositivo offline. Checar conexão à internet.");
    
  }

  // const agendaData = await getAgendaDataById(agendaId)
  
  // console.log("🚀 ~ getAtividadeAnyway2 ~ agendaData:", agendaData)

  // localStorage.setItem(storeNameAgenda, JSON.stringify(agendaData));
  
  // const { atividades } = agendaData;
  // const result = atividades.filter(ativ => ativ.id === atividadeId);
  // return result[0];

  return getActivitySimple(agendaId, atividadeId).get()
    .then(result => {
      console.log('pega simple??')
      getAgendaAndStore(agendaId)
      return result.data()
    })
    .catch(err => {
      return { error: "Erro ao buscar atividade."}
    })  
	
    
}

const getAgendaAndStore = async (agendaId)  => {
  const storeNameAgenda = `agendaState/${agendaId}`;
  const agendaData = await getAgendaDataById(agendaId)
  localStorage.setItem(storeNameAgenda, JSON.stringify(agendaData));

  const { atividades } = agendaData;
  const result = atividades.filter(ativ => ativ.id === atividadeId);
  return result[0];

}


const agendaByDay = (ativs, days) => {
  if (ativs && ativs.length > 0) {
    let aByDay = ativsByDay(ativs);
    return agendaMensal(days,aByDay);
  }
  return null
}

const filteredDays = (atividades, days) => {
    
  if (atividades && days && atividades.length > 0 && days.length > 0) {
    
    let result = atividades.reduce((val, element) => {        
      if (element.eventos) {
        
        
        element.eventos.forEach(e => {
          
          val = [...val, moment(e.dateTime.toDate()).format('DD/MM/YYYY')]
        })
      }

      return val;
    }, [])
    
    let daysWithActivity = [...new Set(result.sort())]; 
    // console.log("🚀 ~ file: agendaService.js:124 ~ filteredDays ~ daysWithActivity:", daysWithActivity)
    // console.log("🚀 ~ file: agendaService.js:122 ~ filteredDays ~ um exemplo:", moment(days[0].data.seconds).format('DD/MM/YYYY'))
    
    let filteredDays = days.filter(d => daysWithActivity.includes(moment(d.data.toDate()).format('DD/MM/YYYY')))
    // console.log("🚀 ~ file: agendaService.js:128 ~ filteredDays ~ filteredDays:", filteredDays)
    
    return filteredDays;
  }

  return null;

  
}

export {
  getAgendaAnyway, 
  getAtividadeAnyway,
  getAtividadeAnyway2, 
  agendaByDay, 
  filteredDays,
  getAgendaData,
  getAgendaDataById
}