import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import './AgendaCalendario.css'

import moment from 'moment';
import 'moment/locale/pt-br';
// import FavButton from "./FavButton";
// import mainColors from '../constants/color';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import mainColors from '../../constants/color';
import AtividadeAgenda from "../AtividadeAgenda/AtividadeAgenda";

const customDay = {
  fontSize: 30,
}

const dayRender = (day, agenda) => {

  // if (day.atividades) {
  //   return day.atividades.map((item, index) => <AtividadeAgenda key={index} item={item} index={index} agenda={agenda} />)
  // } 
  // return dayWithoutAtivs(day);  

  if (day.atividades) {

    const closed = !day.aberto ? dayWithoutAtivs(day) : <div></div>

    return (
      <div>
        {closed}
        {day.atividades.map((item, index) => <AtividadeAgenda key={index} item={item} index={index} agenda={agenda} />)  }
      </div>
    )
  } 

  
  return dayWithoutAtivs(day);  
}

const tituloAtividade = (atividade) => {

  const local = atividade?.origemArtista ? `(${atividade.origemArtista})` : '';

  if (atividade.nome === atividade.grupoArtista) 
    return (
      <div className="title">{atividade.nome} {local}</div>
    )
  else {
    return (
      <div>
        <div className="title">{atividade.nome}</div>
        <i>{atividade.grupoArtista} {local}</i>
      </div>
    )
  }
}


const renderAtividade = (item, index, agenda) => {

  let horas = moment(item.evento.dateTime).format('HH[h]');
  let minutos = moment(item.evento.dateTime).format('mm');

  const horaString = minutos !== '00' ? horas + minutos : horas;
  const titulo = tituloAtividade(item.atividade)

  return (
    <div key={index} className='py-1' style={{fontSize: 15}}>
      <div className="grey-title"><b>{item.atividade.area.toUpperCase()} - {item.atividade.programa}</b></div>
      

      {titulo}
      
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faClock} fixedWidth/> {horaString}</div>
      <div><FontAwesomeIcon color={mainColors.orangeBNB} icon={faMapMarkerAlt} fixedWidth/> {item.evento.local}</div>
      
      <div className="row">
        <div className="align-self-end py-1 col-md-6 col-sm-12">
          <div className="d-flex justify-content-between flex-wrap">
          
            <div>
              <Link 
                  to={{
                    pathname: `/agenda/${agenda.id}/atividade/${item.atividade.atividadeId}`,
                    state: {
                      atividade: item.atividade, 
                      agenda: agenda, 
                      index: index
                    }
                  }} 

                  className="btn btn-outline-primary btn-sm">
                  + Detalhes
                </Link>
              </div>
              
              <div></div>
          </div>
        
        </div>
      </div>
      
      
    </div>  
  )
}

const dayWithoutAtivs = (day) => {

  let open = day.aberto;
  let aberto = open ? 'Centro Cultural aberto para visitação às bibliotecas e exposições' : 'Centro Cultural fechado';

  aberto = day?.detalhe ? `${aberto} - ${day.detalhe}` : aberto

  if (open) {
    return (<div>{aberto}</div>)  
  } else {
    return (<div class="pb-3"><b>{aberto}</b></div>)  
  }

}

const AgendaCalendario = (props) => {

  const { byDay, agenda } = props;

  moment().locale('pt-br');
  const todayString = moment(new Date()).format('DD/MM/YYYY');
	
  if (byDay) {
    return (
      <div className="AgendaCalendario">
        { byDay.map((day, index) => {
          let classesNames =
            day.dateString === todayString
              ? "d-flex my-3 todayClass"
              : "d-flex my-3";
  
          return (
            // <div className="d-flex m-3" key={day.dateString}
            <div
              className={classesNames}
              key={day.dateString}
              // ref={ref => this.logKey(ref, day)}
            >
              <div
                className="w-25 px-2 mr-2 align-items-start d-flex flex-column"
                style={
                  index % 2 === 0
                    ? { backgroundColor: "#A9A9A9", minWidth: 50 }
                    : { backgroundColor: "#D3D3D3", minWidth: 50 }
                }
              >
                <span className="mx-auto" style={customDay}>
                  {moment(day.dateString, "DD/MM/YYYY").format("DD")}
                </span>
                <span className="mx-auto pb-2">
                  {moment(day.dateString, "DD/MM/YYYY").format("ddd")}
                </span>
                { day.dateString === todayString && <span className="mx-auto pb-2">(HOJE)</span>}
              </div>
  
              <div
                className="py-2 px-3 col-md bd-highlight"
                style={{ backgroundColor: "#eeee" }}
              >
                {dayRender(day, agenda)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return (<div></div>)
  
  
};

// const mapStateToProps = state => ({
//   agenda: state.agendaState.agenda,
//   byDay: state.agendaState.byDay,
// });

// export default connect(mapStateToProps)(AgendaCalendario);


// const connectedAgendaCalendario = connect(mapStateToProps)(AgendaCalendario)

export default AgendaCalendario;
